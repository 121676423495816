<template>
    <section id="sp-slider" class="sp-slider pb-2">

        <!-- Buttons navigation -->
        <div data-aos="zoom-in" class="row mb-0 mb-md-5" 
            style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">

            <div class="col-6 col-md-1 order-1 order-md-1 flex-center px-0" v-if="buttons_navigation">
                <button @click="changeShowItem(backElement(item_current)); scrollBack()"
                    class="btn btn-sm btn-outline-danger hover-image m-1 py-0">
                        <img :src="arrow_back" width="36" height="36">    
                </button>
            </div>
            <div class="col-12 col-md-10 order-3 order-md-2 px-0"
                style="overflow: auto; white-space: nowrap;" ref="scrollContainer"
                v-if="buttons_navigation">
                    <button @click="changeShowItem(index)"
                        v-for="(value, index) in items" :key="index"
                        class="btn btn-sm m-1"
                        :class="(item_current == index) ? `btn-danger` : 'btn-secondary'">
                            
                            {{ value.title }}
                    </button>
            </div>
            <div class="col-6 col-md-1 order-2 order-md-3 flex-center px-0" v-if="buttons_navigation">
                <button @click="changeShowItem(nextElement(item_current)); scrollNext()"
                    class="btn btn-sm btn-outline-danger hover-image m-1 py-0">
                        <img :src="arrow_next" width="36" height="36">
                </button>
            </div>
        </div>
        

        <!-- Slider -->
        <div :id="_id"
             class="sp-slider--container h-100" 
             :style="`width: ${percent_total_items}%;`">

            <div v-for="(value, index) in items" :key="`item-${index}`"
                :style="`width: ${percent_item}%;`"
                style="height: 100%;">

                    <div class="row flex-center">

                        <div :data-aos="(variant == 1) ? 'fade-right' : 'fade-left'"
                            class="col-12 col-md-6 flex-center-start element-50 px-2 px-md-4 h-100 py-0 pb-4 pb-md-0 order-1"
                            :class="`${(variant == 1) ? 'order-md-1' : 'order-md-2'}`">

                            <div style="max-width: 700px; width: 100%;">
                            
                                <div class="btn-pill text-center mb-4 mt-4 mt-md-0"
                                    :class="`${((index + 1) % 2 == 1) ? 
                                        ((variant == 1) ? 'bg-danger' : 'bg-gray') :
                                        ((variant == 1) ? 'bg-gray' : 'bg-danger') }`">

                                    <span class="montserrat-bold text-white" 
                                        style="font-size: clamp(22px, 3vw, 28px);">

                                        {{ value.title }}
                                    </span>
                                </div>

                                <div class="text-justify" style="max-height: 40vh !important; overflow-y: scroll;">
                                    <span v-html="value.text">
                                    </span>    
                                </div>

                                <div class="d-flex justify-content-center pt-5" 
                                    v-if="value.gallery">

                                    <button @click.stop="showModal(value)"
                                            
                                        class="text-danger bg-white"
                                        style="width: 280px; height: 40px; border: 3px solid red; border-radius: 15px;">
                                        Más información
                                    </button>
                                </div>
                                
                            </div>
                        </div>

                        <div :data-aos="(variant == 1) ? 'fade-left' : 'fade-right' "
                            class="col-12 col-sm-10 col-md-6 flex-center element-50 px-0 px-md-4 h-100 order-2" 
                            :class="`${(variant == 1) ? 'order-md-2' : 'order-md-1'}`">

                            <div style="max-width: 700px;">
                                <!-- <img :src="value.carousel_internal[0]" 
                                    class="img-fluid"> -->

                                <sp-slider :_id="value.id_carosuel_internal" 
                                    :items="value.carousel_internal"
                                    :show_dots="false"
                                    :autoplay="true"
                                    :time_autoplay="3000">

                                </sp-slider>

                            </div>
                        </div>

                        <!-- <div class="col-12 d-flex justify-content-between align-items-center 
                            order-md-0 px-0 px-md-4 pb-2" 
                            style="vertical-align: middle" 
                            v-if="show_next_item">

                            <button class="btn btn-outline-danger">
                                item aterior
                            </button>
                            <button class="btn btn-danger">
                                <span href="#" class="cursor-pointer montserrat-black" 
                                    @click="changeShowItem(nextElement(index))">
                                    {{ showNextElement(index) }}
                                </span>
                                <img :src="arrow_next">
                            </button>
                                
                        </div> -->

                        

                    </div>
            </div>
        </div>

        <div :id="`footer_${_id}`" class="sp-slider--footer pt-2" v-show="show_dots">
            <div class="sp-slider-dot" 
                @click="changeShowItem(index)"
                v-for="(item, index) in items" :key="`dot-${index}`">
                    
            </div>
        </div>
    </section>
</template>

<script>
    import arrow_back from 'ASSETS/images/icon/arrow_back'
    import arrow_next from 'ASSETS/images/icon/arrow_next'

    export default {
        data() {
            return {
                item_current: null,
                autoplay_interval: null,
                arrow_back,
                arrow_next
            }
        },
        computed: {
            total_items() {
                return this.items.length
            },
            percent_total_items() {
                return 100 * this.total_items
            },
            percent_item() {
                return 100 / this.total_items
            }
        },
        props: {
            _id: {
                type: String,
                required: true,
                default: '',
            },
            items: {
                type: Array,
                required: true,
                default: '',
            },
            autoplay: {
                type: Boolean,
                required: false,
                default: true,
            },
            time_autoplay: {
                type: Number,
                required: false,
                default: 2000,
            },
            show_dots: {
                type: Boolean,
                required: false,
                default: true,
            },
            variant: {
                type: Number,
                required: false,
                default: 1
            },
            // show_next_item: {
            //     type: Boolean,
            //     required: false,
            //     default: false,
            // },
            buttons_navigation: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        methods: {
            changeShowItem(item) {

                this.item_current = item;

                let dots = document.getElementById(`footer_${this._id}`).getElementsByClassName("sp-slider-dot");
                for (let i in dots) {
                    try {
                        dots[i].classList.remove("dot-active");
                    } catch (e) {}
                }
                
                dots[this.item_current].classList.add("dot-active");

                let slider_container = document.querySelector(`#${this._id}`);

                let percent_current = (item == 0) ? 0 : (-1 * (this.percent_item * item));
                
                slider_container.style.transform = `translateX(${percent_current}%)`;

                // centrar el item
                this.centerItemInScroll(item);
            },
            showModal(value) {
                this.$emit('opennModal', value)
            },
            centerItemInScroll(item) {
                const container = this.$refs.scrollContainer; 
                const items = container.children; 
                const selectedItem = items[item]; 


                const containerWidth = container.clientWidth;
                const itemWidth = selectedItem.clientWidth;
                const itemLeft = selectedItem.offsetLeft;


                const scrollPosition = itemLeft - (containerWidth / 2) + (itemWidth / 2);
                container.scrollLeft = scrollPosition;
            },
            scrollBack() {
                const container = this.$refs.scrollContainer;
                const scrollAmount = container.clientWidth * 0.1;
                container.scrollLeft -= scrollAmount;
            },
            scrollNext() {
                const container = this.$refs.scrollContainer;
                const scrollAmount = container.clientWidth * 0.1;
                container.scrollLeft += scrollAmount;
            },
            backElement(index) {
                return (index > 0) ? index - 1 : this.total_items - 1;
            },
            nextElement(index) {
                return (index < this.total_items - 1) ? index + 1 : 0;
            }
        },
        mounted() {
            this.item_current = 0
            this.changeShowItem(this.item_current)

            if(this.autoplay) {
                let This = this

                this.autoplay_interval = setInterval(function() {
                    if(This.item_current < This.total_items - 1) {
                        This.item_current = This.item_current + 1    
                    }
                    else {
                        This.item_current = 0
                    }
                    
                    This.changeShowItem(This.item_current)

                }, This.time_autoplay)
            }
        },
        unmounted() {
            clearInterval(this.autoplay_interval)
        }
    }
</script>

<style lang="scss" scoped>
    @import 'ASSETS/scss/minxin.scss';

    .sp-slider {
        width: 100%;
        overflow-x: hidden;

        &--container {
            height: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            transition: all .5s ease;
            transform: translateX(0%);

            .element-50 {
                @include upPixels(768px) {
                    min-height: 10vh
                }    
            }
        }

        &--footer {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            .sp-slider-dot {
                width: .7em;
                height: .7em;
                border-radius: 1em;
                background-color: rgb(204, 204, 204);
                margin: .6em;
                cursor: pointer;
            }
            .dot-active {
                background-color: #FF0000 !important;
            }
        }
    }
</style>
